#about{
    position: relative;
    background: url('../../assets/mobileNavBg.jpg');
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    

    
}

.app__header-about {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
  
    padding: 2rem 2rem 0rem;
  
    @media screen and (min-width: 2000px) {
      padding-top: 8rem;
    }
  
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  
    @media screen and (max-width: 450px) {
      padding: 3rem 1rem 2rem;
    }
  }
  .app__header-about-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  
    margin: 0 2rem;
  
    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-right: 0rem;
    }
    .app__about-brand{
      width: 80%;
      flex-wrap: wrap;
      margin-top: 2rem;
      
  
      div{
          width:150px;
          margin:1.5rem;
          
          img{
              width:100%;
              height: auto;
              object-fit: cover;
              filter: grayscale(1);
              border-radius: 15px;
              box-shadow: 0 0 30px rgba(0,0,0,1);
              transition: all 0.3s ease-in-out;
          }
  
          &:hover{
              img{
                  filter:grayscale(0);
              }
          }
          @media screen and (min-width: 2000px){
              width: 210px;
              margin: 2rem;
  
              
          }
          @media screen and (max-width: 450px){
              width: 120px;
              margin: 1rem;
              
              
          }
      }
      @media screen and (max-width: 800px){
          width: 100%;
          
          
      }
  
  
  }
  }

  


  .app__header-about-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  
    .badge-cmp,
    .tag-cmp {
      padding: 0.5rem 0.5rem;
      background: var(--white-color);
      border-radius: 15px;
      flex-direction: row;
      width: auto;
      color: var(--secondary-color);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
  
    .tag-cmp {
      flex-direction: column;
      margin-top: 1rem;
  
      p {
        width: 100%;
        text-transform: uppercase;
        text-align: center;
        color: var(--secondary-color); 
      }
    }
}


.app__about{
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__profiles{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 0;
}


.app__profile-item{
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;

    .p-text{
        color:var(--secondary-color);
    }
    

    img{
        width: 100%;
        height: 170px;
        object-fit: cover;
    }
    @media screen  and (min-width: 2000px) {
        width: 370px;
        margin:2rem 4rem;

     
        img{
            height: 320px;
            
        }
    }
}


.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}